import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { setLocalstorageData } from "../utils/localStorageHelper";

export const ProtectedRoute = ({ children }: { children: any }) => {
  const { user } = useAuth();
  const location = useLocation();
  const hrCode = location.pathname.replace("/", "");

  console.log(user);
  if (!user) {
    if (hrCode) {
      setLocalstorageData({ key: "hrcode", value: hrCode });
    }
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};
